
.toggle-view{
    display: flex;
    justify-content: flex-end;
    .ant-switch{
        margin: 10px 0px;
        height: 30px;
        .ant-switch-handle{
            top: 5px;
            left: 7px;
            width: 20px;
            height: 20px;
        }
        .ant-switch-inner{
            margin: 4px 7px 5px 30px;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
        }
    }
    .ant-switch-checked{
        .ant-switch-handle{
            left: calc(100% - 18px - 7px);
        } 
        .ant-switch-inner{
            margin: 4px 30px 5px 7px !important;
        }
    }
}