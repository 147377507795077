.appraisal-container {
    background-color: #F3F5F6;
    // height: calc(100% - 50px);
    padding: 0px 24px 24px 24px;

    .ant-card {
        border: 1px solid #E5E7EA;
        border-radius: 4px;
    }
}

.content-collapsed {
    width: calc(100% - 80px);
    transition: all 0.2s;
}

.content-expanded {
    width: 100%;
    transition: all 0.2s;
}

.personal-detail-card {
    .ant-card-body {
        padding: 15px !important;

        .header01 {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .title-action {
            .title01 {
                // font-family: "Rubik";
                font-weight: 600;
                font-size: 30px;
                line-height: 32px;
                color: #0A0909;
                padding-bottom: 8px;
            }

            .request-id {
                // font-family: "Rubik";
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #0A0909;

                .label01 {
                    color: #2F3E4680;
                }
            }
        }

        .update-action-select {
            width: 200px;

            .ant-select-selector {
                background: #1B875A;
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                border-radius: 4px;
                border: none;
            }

            .ant-select-arrow {
                color: #fff;
                font-size: 14px;
            }
        }

        .status-bar {
            margin: 20px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ant-tag-red {
                padding: 8px;
                border: none;
                color: #B53328;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                background-color: #F8EBE9;
                display: flex;
            }
        }

        .personal-detail-tags-container {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }

        .personal-detail-card {
            text-align: center;
            align-items: "center";
            justify-content: "center";
            overflow: "hidden";
            display: "flex";
            border-radius: 8px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        }

        .personal-detail-card-title {
            font-size: 20px;
            font-weight: bold;
            margin: 0;
        }

        .personal-detail-card-subtitle {
            font-size: 16px;
            color: #595959;
            margin: 0;
        }


        .request-id {
            >span {
                .label01 {
                    font-weight: bold;
                }

                +span {
                    margin-left: 20px;
                }
            }
        }

        .overview-section {
            .text {
                color: #98A9BC;
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
                margin-top: -10px;
                margin-bottom: 15px;
            }

            .overview01 {
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
            }

            .row {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                max-height: 250px;
                overflow-y: hidden;
                flex-wrap: nowrap;
                overflow-x: auto;
                box-sizing: border-box;
                width: 100%;
                margin-left: 0px;
            }

            .section02 {
                flex: 1;
                margin-right: 10px;
                min-height: 200px;
                padding: 12px;
                border-radius: 4px;
            }

            .section02:last-child {
                margin-right: 0px;
            }

            .bank-analysis-section {
                overflow-y: auto;
                overflow-x: auto;
            }

            .cibil-score-section {
                flex: 1;
                background-color: #F7FAF6;
            }

            .cibil-score-section {
                background-color: #FDF8FB;
            }

            .emi-loan-section {
                flex: 2;
                display: flex;
                flex-direction: column;
                background-color: #F0F6FE;
                padding: 10px;
                border-radius: 4px;
                overflow-y: auto;
                overflow-x: auto;
                margin-bottom: 0px;
                // min-height: 303px;
                max-height: 200px;
            }

            .emi-loan-section .ant-table {
                background-color: #F0F6FE;
                width: 100%;
                margin: 0;
            }

            .emi-loan-section .ant-table-container {
                border: 0;
            }

            .emi-loan-section .ant-table-thead>tr>th {
                background-color: #F0F6FE;
            }

            .title-section {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                img {
                    width: 30px;
                    height: 30px;
                }

                .title02 {
                    color: #2F3E46;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    margin-left: 10px;
                }
            }

            .list-section {

                .list01,
                .list02 {
                    margin: 20px 0px;
                    display: flex;
                    justify-content: space-between;
                }

                .label01,
                .value01 {
                    color: #2F3E46;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                }

                .value01 {
                    color: #2F3E46B2;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 400;
                    margin-left: 15px;
                }
            }
        }

    }
}

.comments-card {
    min-height: 440px;
}

.tabs-card {
    width: 100%;
    margin-top: 20px;

    .tab-main {
        .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
            margin-top: 0px !important;
            padding: 0px !important;
        }

        .tab-list {
            padding: 15px 10px 10px 10px;
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid #F2F4F6;
            height: 70px;
            width: 290px;
            justify-content: flex-start;
            min-height: 70px;

            .right01 {
                .tab-name {
                    display: flex;
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 600;
                    color: #0A0909;
                    padding-bottom: 10px;
                }

                .tab-detail {
                    font-size: 12px;
                    line-height: 14px;
                    color: #2F3E4680;
                    display: flex;
                }
            }

            .img02 {
                width: 20px;
                height: 20px;
                color: #889AAD;
            }

            .ant-tag-red {
                border: none;
                color: #B53328;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                background-color: #F8EBE9;
                margin-right: 0px;
                padding: 5px 10px;
                margin-left: auto;
            }
        }

        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: #52796F;

                .tab-list {
                    background-color: #CAD2C5;
                    border: none;

                    .img02 {
                        color: #52796F;
                    }

                    .tab-name {
                        color: #52796F;
                    }

                    .tab-detail {
                        color: #2F3E4680;
                    }
                }
            }
        }
    }

    .emp-info-form {
        .title {
            color: #0A0909;
            font-size: 24px;
            line-height: 26px;
            font-weight: 600;
        }

        .form01 {
            .info-input-row {
                margin-bottom: 20px;

                .label {
                    font-size: 12px;
                    line-height: 14px;
                    color: #2F3E4680;
                    margin-bottom: 8px;
                }

                .value {
                    font-size: 16px;
                    color: #0A0909;
                    line-height: 18px;
                    font-weight: 600;
                }
            }

            .detail-blocks {
                margin: 10px 0px;

                .block-title {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    font-weight: 600;
                }

                .block-title:after {
                    background-color: #E5E7EA;
                    content: "";
                    display: inline-block;
                    height: 1px;
                    position: relative;
                    vertical-align: middle;
                    width: 80%;
                    margin-left: auto;
                }
            }

            .add-per-doc-block {
                .block-title:after {
                    width: 75%;
                }

                .add-per-doc-header {
                    margin-left: -12px;
                    margin-right: -12px;
                    height: 50px;
                    align-items: center;
                    background-color: #D8D8D81F;
                    border-radius: 4px;

                    .ant-col {
                        font-size: 14px;
                        line-height: 16px;
                        color: #2F3E4680;
                        padding: 0px 20px;
                    }

                    .col-divide:after {
                        width: 1px;
                        background-color: #E5E7EA;
                        height: 100%;
                    }
                }

                .add-per-doc-row {

                    margin-top: 15px;
                    margin-bottom: 15px;
                    align-items: flex-start;
                }
            }
        }

        .emi-head-action {
            display: flex;
            justify-content: center;
            align-items: center;

            .save-btn {
                color: #1B875A;
                font-size: 16px;
                font-weight: 600;
                margin-left: auto;
            }
        }

        .enach-btn {
            color: #1B875A;
            border-color: #1B875A;
            margin-right: 15px;
        }

        .enach-btn:hover {
            color: #1B875A;
            border-color: #1B875A;
        }
    }

    .bank-action-btn {
        height: 48px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
        border-radius: 4px;
        border: 1px solid;
    }
}

.small-card {
    padding: 0 !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    min-height: 350px;
}

.small-card .ant-card-head {
    min-height: 0 !important;
    margin: 0 !important;
    margin-left: 15px;
    padding: 5px !important;
}

.small-card .ant-card-body {
    padding: 5px;
}

.small-card .ant-card-head-title {
    padding: 0 !important;
    margin: 0 !important;
    min-height: 0 !important;
    font-size: 16px !important;
}

.small-title {
    margin: 0px !important;
    font-size: 14px !important;
}

.small-title+.small-table {
    margin-bottom: 8px;
    /* Add margin between title and table */
}

.small-table .ant-table {
    padding: 0 !important;
    margin: 0 !important;
}

.small-table .ant-table-cell {
    padding: 0 !important;
    font-size: 12px !important;
}

.custom-menu-item {
    background-color: #2F3E46;

    color: #2F3E46;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding-left: 15% !important;
    padding-right: 0% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    display: flex;
    height: 60px !important;
    align-items: left !important;
    width: 100%;
    background-color: #2F3E46 !important;

    .custom-menu-link {
        display: flex;
        align-items: center;
        color: #ffffff !important;
        font-weight: 500 !important;

        &:hover {
            color: #ffffff;
        }
    }

    &.ant-menu-item-selected {
        background-color: #1B875A !important;
        color: #2F3E46;
    }
}

.custom-sider .ant-layout-sider-trigger {
    background-color: #2F3E46 !important;

    &:hover {
        background-color: #24303a !important;
    }

}

.custom-sider .ant-layout-sider-trigger .anticon {
    color: #ffffff;
}