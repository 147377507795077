.antd-button {
  transition: all 0.3s ease;
}

.antd-button:hover {
  filter: brightness(90%);
}

.antd-button:active {
  transform: scale(0.95);
}